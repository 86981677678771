body {
    background-color: #ecefef;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: red;
    padding: 5px;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 13px 15px;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
   flex-direction: column;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
    text-align: center;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

.pagination-define{
    text-align: center
}
.pagination-define input, .pagination-define select {
    padding-left: 3px;
    height: 30px;
    vertical-align: top;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 50px;
}
.pagination {
    margin: 0;
}
.pagination-define .btn-30h {
    vertical-align: top;
}
.btn-30h {
    padding-top: 4px;
    padding-bottom: 4px;
}

/* --------------------------------------------------------------------------分页器样式全局覆盖 */
jhi-item-count{
    color:rgba(200, 200, 200, 1);
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
}
.pagination-sm > li > a, .pagination-sm > li > span{
    min-width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding:0 4px;
    border-radius: 3px;
    border: 1px solid #DBDBDB;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #787878;
    margin: 5px 5px 0 5px;
}
.pagination >li:not(.disabled)> a:hover,.pagination > .active > a{
    color:#ffffff;
    background: linear-gradient(133deg,rgba(250,118,145,1) 0%,rgba(244,65,88,1) 100%);
    border-color:transparent ;
}




*{font-family:'Open Sans','Microsoft YaHei';padding:0;margin:0;border:0;list-style-type:none;}
h1,h2,h3,h4,h5,h6{font-weight:normal;}
html{min-height:100%; border:none;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}
/*body{*/
/*word-wrap:break-word;word-break:break-all; background-color: #ffffff;*/
/*}*/

ul,ol{list-style-type:none;}
button,submit,input,select,textarea{outline:none;}
button,submit,input,select{vertical-align:middle;}
button,submit,input[type="button"]{cursor:pointer; -webkit-appearance: none;}
textarea{resize:none;outline:none;}
button::-moz-focus-inner,
submit::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner{padding:0;border:none;}
table{border-collapse:separate;border-spacing:0;}
th,td{text-align:left;}
address,cite,dfn,em,var{font-style:normal;}
img{border:none;vertical-align:middle;}
a{outline:none; cursor:pointer;}
a:link,a:visited{text-decoration:none;outline:none;}
a:hover,a:active{outline:none;}
iframe{vertical-align:top;}
*html{zoom:expression(function(ele){ele.style.zoom = "1";document.execCommand("BackgroundImageCache",false,true)}(this))}
.popovers{
    width: 20px;
    height: 20px;
    border-radius:50%;
    text-align:center;
    line-height:20px;
    color:#969696;
    background-color: #ffffff;
    border: solid 2px #969696;
    float: right;
    position: absolute;
    margin:12px 0 0 5px;
}
/*.arrow{*/
/*color: #fff9ba;*/
/*border:none;*/
/*}*/
.popover,.popover-title,.popover-content,.bottom{
    font-family: PingFangSC;
    height: auto!important;
    color: #5a5a5a;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    border:none;
    border-radius: 5px;
    background-color: #fff9ba!important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
}
.button-myself{
    width: 225px;
    height: 45px;
    color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
    background: -webkit-linear-gradient(left,#fb7691, #f44158);
    background: -o-linear-gradient(right, #fb7691, #f44158); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #fb7691, #f44158); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #fb7691 , #f44158); /* 标准的语法 支持ie10以上*/
    /*background: rgb(244, 65, 88);*/
    box-shadow: 0 3px 9px 0 rgba(247, 93, 118, 0.49);
    margin-left: 50px;
}
.button-myself-cancel{
    width: 225px;
    height: 45px;
    color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
    background-image: linear-gradient(101deg, #ababab, #969696);
    box-shadow: 0 6px 12px 0 rgba(132, 132, 132, 0.49);
}
.button-myself-cancel-clear{
    float: left;
    width: 100px;
    height: 45px;
    color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
    background-image: linear-gradient(101deg, #ababab, #969696);
    box-shadow: 0 6px 12px 0 rgba(132, 132, 132, 0.49);
}
.button-myself-cancel-saveAndOut{
    float: inside;
    width: 150px;
    height: 45px;
    color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
    background-image: linear-gradient(101deg, #ababab, #969696);
    box-shadow: 0 6px 12px 0 rgba(132, 132, 132, 0.49);
    position:relative;
    left:5%;
}
.button-myself-saveAndJump{
    float: right;
    width: 225px;
    height: 45px;
    color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
    background: -webkit-linear-gradient(left,#fb7691, #f44158);
    background: -o-linear-gradient(right, #fb7691, #f44158); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #fb7691, #f44158); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #fb7691 , #f44158); /* 标准的语法 支持ie10以上*/
    /*background: rgb(244, 65, 88);*/
    box-shadow: 0 3px 9px 0 rgba(247, 93, 118, 0.49);
    margin-left: 50px;
}
.checkBtn{
    width: 93px;
    height: 40px;
    color: #ffffff;
    border-radius: 4px;
    background-image: linear-gradient(113deg, #35e8c0, #0c9fcb);
    box-shadow: 0 4px 8px 0 rgba(80, 197, 212, 0.31);
}
.changeStatus{
    width: 93px;
    height: 40px;
    color: #ffffff;
    border-radius: 4px;
    background-color: #dcdcdc;
}
.showDetailBtn{
    width: 93px;
    height: 40px;
    color: #ffffff;
    border-radius: 4px;
    background-image: linear-gradient(113deg, #3e566f, #1d2b3c);
    box-shadow: 0 4px 8px 0 rgba(29, 43, 60, 0.2);
}
.whiteBtn{
    width:80px;
    height:40px;
    background:rgba(255,255,255,1);
    border-radius:4px;
    border:1px solid rgba(200,200,200,1);
}
.returnEdit{
    width: 93px;
    height: 40px;
    color: #ffffff;
    border-radius: 4px;
    background-image: linear-gradient(112deg, #fb7691, #f44158);
    box-shadow: 0 4px 8px 0 rgba(244, 65, 88, 0.3);
}
.tripMore{
    width: 60px;
    height: 40px;
    color: #787878;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;
}
.eAccountIdent{
    /*width: 100%;*/
    /*height: 100%;*/
    background: url(/content/images/background.jpg) repeat center center fixed;
}
.eAccountSave{
    width: 100%;
    height: 45px;
    color: #ffffff;
    border-radius: 5px;
    background-image: linear-gradient(95deg, #35e8c0, #0c9fcb);
    box-shadow: 0 4px 8px 0 rgba(80, 197, 212, 0.31);
}
.eAccountSubmit{
    width: 100%;
    height: 45px;
    color: #ffffff;
    border-radius: 5px;
    background-image: linear-gradient(95deg, #fb7691, #f44158);
    box-shadow: 0 6px 12px 0 rgba(247, 93, 118, 0.49);
}

.foot {
    /*position:fixed;*/
    margin-top: -100px;
    bottom:0;
    width:100%;
    text-align: center;
    font-size: 16px;
    background-color: #ffffff;
    opacity: 0.9;
    /*height: 133px;*/
    border-top: solid 1px rgba(255, 255, 255, 0.6);
    z-index: 10;
}
.table1 table{
    border: 1px solid #00b3ee;
    text-align: center;
    color: red;
    font-weight: bolder;
}

.table1 table tr td{
    border: 1px solid #00b3ee;
    width: 15rem;
    height: 15rem;
    font-size: 15px;
}


.table2 table{
    border: 1px solid #00b3ee;
    text-align: center;
    color: red;
    font-weight: bolder;
}

.table2 table tr td{
    border: 1px solid #00b3ee;
    width: 10rem;
    height: 10rem;
    font-size: 15px;
}


.table3 table{
    border: 1px solid #00b3ee;
    text-align: center;
    color: red;
    font-weight: bolder;
}

.table3 table tr td{
    border: 1px solid #00b3ee;
    width: 7.5rem;
    height: 7.5rem;
    font-size: 15px;
}

.table4 table{
    border: 1px solid #00b3ee;
    text-align: center;
    color: red;
    font-weight: bolder;
}

.table4 table tr td{
    border: 1px solid #00b3ee;
    width: 6rem;
    height: 6rem;
    font-size: 15px;
}
.coverImg{
    border-radius: 5px;
    width: 30rem;
    height: 30rem;
}
.normal{
    max-height: 30rem;
    max-width: 30rem;
    border-radius: 5px;
}

.left ul{
    font-size: 1.5rem;
    color: #757575;
    margin-top: 2.2rem;
}

.left img{
    margin-top: 1.3rem;
    width: 10rem;
    height: 3.5rem;
}

/*.right img{*/
/*margin-top: .11rem;*/
/*}*/

.bottom {
    height: 3rem;
    background-color: #e8383d;
}

.use_license {
    /*width: 100px;*/
    height: 20px;
    opacity: 0.8;
    font-size: 14px;
    font-weight: 500;
    color: #f3f3f3;
}

.Copyright {
    font-size: .5rem;
    margin-top: 2.2rem;
}

.text {
    /*width: 500px;*/
    height: 17px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.1px;
    color: #f3f3f3;
}

.download {
    font-size: 1.1rem;
    color: #a8a8a8;
    margin-right: 0.3rem;
    margin-top: 6px;
}

.download_img {
    height: 6rem;
    width: 6rem;
    text-align: right;
}

/**
    add by Harlan
 */
.loginwell {
    text-align: center;
    width: 100%;
    /*padding-top: 4%;*/
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    /*-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);*/
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);*/
    background-color: transparent;
    border: none;
}

.loginlogo {
    margin-bottom: 30px;
}

/*******************************  modify by skygreen:2017-8-30:start  *************************/
/*******************************  布局顶部导航条:start  ***********************************/
*{
    font-family: PingFangSC, 'Microsoft YaHei',"微软雅黑",Arial, sans-serif,'Open Sans' !important;
}
.glyphicon{
    font-family: 'Glyphicons Halflings' !important;
}
.ribbon{
    top: 20px;
    left: -65px;
}
body,.con{
    height: 100% !important;
}

.loading-modal {
    display: none;
}
.loading-mobile {
    display: none !important;
}
/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .loading-modal {
    display: none;
    position:   fixed;
    z-index:    10000;
    top:        0;
    /*left:       0;*/
    height:     100%;
    width:      100%;
    /*background: rgba( 255, 255, 255, .8 )*/
    background: rgba( 255, 255, 255, 1 )
    url('/content/images/loading.gif')
        /*url('/content/images/loading2.swf')*/
    50% 50%
    no-repeat;
}
body.loading .loading-mobile {
    display: block;
    position:   fixed;
    z-index:    10000;
    top:        0;
    /*left:       0;*/
    height:     100%;
    width:      100%;
    /*background: rgba( 255, 255, 255, .8 )*/
    background: url('/content/images/bac-mobile.png')
    50% 50%
    no-repeat;
}

#nav{
    background-color: #1d2b3c;

}
.navbar-default{
    margin: 0 auto;
    height: 66px;
    padding-left: 4%;
}
.navbar-brand > img{
    height: 40px;
    width: 105px;
}
.navbar-default .navbar-nav > li > a:focus,.navbar-default .navbar-nav > li > a:hover {
    color: #f44158;
    background-color: transparent;
    /*border-bottom: #f44158 5px solid;*/
}
.navbar-default .navbar-nav > li > a{
    font-size: 14px;
    height: 66px;
    line-height: 35px;
}

.navbar-nav > li.nav-user{
    position: absolute;
    margin-left: 70%;
    right: 4%;

}
.navbar-nav > li.nav-user .dropdown-menu.pull-right{
    margin-right: 0px;
}
/*.carets{*/
/*margin-top: -28px;*/
/*}*/
/*.author{*/
/*font-size: 12px;*/
/*margin-top: -8px;*/
/*margin-left: -27px;*/
/*}*/
/*.pgc{*/
/*font-size: 13px;*/
/*margin-top: -48px;*/
/*}*/
.navbar-toggle{
    margin-top: 16px;
}
.navbar-default .navbar-toggle .icon-bar{
    background-color: #fff;
}
@media (max-width: 767px){
    h4{
        font-size: 13px;
        color: #ccc;
    }
    .navbar-collapse{
        margin-left: -31px;
        margin-top: 7px;
    }
    .navbar-default{
        z-index: 1000;
    }
    .navbar-default .navbar-nav > li > a{
        height: 48px;
        border-bottom: 1px solid #555;
        text-align: left;
    }
    .navbar-nav .open .dropdown-menu{
        margin-left: 60px;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        border-bottom: 1px solid #444;
        margin-left: 36px;
        padding-left: 0px;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover{
        color: #999;
    }
    .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover{
        border-bottom: #f44158 1px solid
    }
    .navbar-collapse ul.navbar-nav{
        background-color: #1e2b3b;
        margin-top: 0px;
    }
    .dropdown-menu > li,.dropdown-menu > li > a{
        width: auto;
    }
    .navbar-default .navbar-nav > li > a img{
        margin: auto 10px;
    }
    .navbar-nav > li.nav-user{
        position: relative;
        min-width: 220px;
        float: right;
        margin-top: 5px;
        margin-left: 0 !important;
        right: 0;
    }
    /*.navbar-default .navbar-nav > li > a img.ava{*/
    /*margin-left: -25px;*/
    /*margin-top: -14px;*/
    /*}*/
    /*.pgc{*/
    /*margin-left: 36px;*/
    /*margin-top: -50px;*/
    /*}*/
    /*.author{*/
    /*margin-left: 28px;*/
    /*}*/

    /*.navbar-default .navbar-nav > li > a img.carets{*/
    /*margin: -28px 0 0 0px;*/
    /*}*/
}
/*******************************  布局顶部导航条:end    ***********************************/
/*******************************  布局底部和登录页面样式:start  ****************************/
.login-container{
    background: url(/content/images/background.jpg) repeat center center fixed;
}

.login-container-rabbit{
    background: url(/content/images/rabbit-leader-login.png) repeat center center fixed;
}

.login-container-content{
    width:100%;
    padding:0;
}
.container .con{
    width: auto;
    margin-left: 0px;
}
.container #con{
    min-height: 712px;
}
.loginlogo div,.login-input .login-window{
    width: 350px;
    margin: 0 auto;
}
.loginlogo div img{
    display: block;
    margin: 0 auto;
}
.login-form-control{
    display: inherit;
    margin: auto;
}
.login{
    margin-left: auto;
}

.login-pmt {
    opacity: 0.52;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
}

.footer .foot{
    position: fixed;
    bottom: 0px;
    height: 100px;
    background-color: transparent;
    z-index: 0;
}
.foot .content {
    height:100px;
    width:100%;
    /*max-width: 992px;*/
    min-width: 680px;
    height: 100px;
    margin:0 auto;
}

.foot .content .left{
    float: left;
    width: 60%;
    margin-left: 4%;
    text-align: left;
}
.foot .content .left .use_license a,.foot .content .left .Copyright .text a{
    color: #89adad;
}
.footer .foot .content .left .use_license a{
    font-size: 14px;
    color: #ddd;
}

.foot .content .left .use_license a:hover,.foot .content .left .use_license a:active{
    color:#1d2b3c;
}
.footer .foot .content .left .use_license a:hover,.footer .foot .content .left .use_license a:active{
    color: #fff;
}
.footer .foot .content .left .Copyright .text a {
    color: #ddd;
}
.foot .content .left .Copyright .text{
    font-size: 12px;
    color: #a8a8a8;

}
.foot .content .left .Copyright .text a:hover,.foot .content .left .Copyright .text a:active {
    color: #1d2b3c;
    text-decoration: underline;
}
.footer .foot .content .left .Copyright .text a:hover,.footer .foot .content .left .Copyright .text a:active {
    color: #fff;
}
.foot .content .right{
    margin: 0 auto;
    margin-right: 4%;
    float: right;
}
.foot .content .right ul li{
    float: left;
    margin-right: 40px;
    padding-top: 25px;
}
.foot .content .right ul li:last-child{
    padding-top: 22px;
    margin-right: 0px;
}

@media (max-width: 768px) {
    .footer .foot.mobile{
        position: inherit !important;
    }
}
@media (max-width: 700px){
    /*.loginwell{*/
    /*padding-top: 18%;*/
    /*}*/
    .use_license{
        display: none;
    }
    .footer .foot{
        height: auto;
    }
    .foot .content{
        max-width: 100%;
        min-width: 100%;
        height: 180px;
    }
    .foot .content .right{
        float: none;
        margin-right: 0px;
    }
    .foot .content .left{
        float: none;
        margin-left: 0px;
        width: 100%;
        text-align: center;
    }
    .foot .content .left .Copyright{
        margin-top: 0px;
    }
    .foot .content .left .Copyright .text{
        width: 320px;
        margin: 0 auto;
    }
    .foot .content .right ul li{
        float: none;
        display: inline-block;
    }
    .download_img{
        margin-top: -40px;
    }
}

@media (max-width: 320px){
    /*.loginwell{*/
    /*padding-top: 12%;*/
    /*}*/
    .loginlogo div,.login-input .login-window{
        width: 320px;
    }
    .login,.login-pmt,.login-form-control{
        width: 300px;
    }
    .foot .content{
        height: 150px;
    }
    .foot .content .left .Copyright{
        margin-top: -10px;
    }
}
/*******************************  布局底部和登录页面样式:end    ****************************/

/*购买页面样式*/
.account_box{
    padding-bottom:80px;
    padding-left: 25px;
}
.account_t{
    display:inline-block;
    padding-right:8px;
    text-align:right;
    color:#333;
    font-size:14px;
    font-weight: bold;
    height:35px;
}
/*.dBox em,.dBox em.active,.dBox .inp_dmoney{ vertical-align:middle; position:relative;}*/
.dBox1{
    width:100%;
    height:100%;
    display: block;
}
.dBox1 em{
    display:inline-block;
    width: 146px;
    height: 183px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    font-style:normal;
    margin-right:16px;
    font-size:14px;
    cursor:pointer;
    color:#1d2b3c;
    text-align: center;
    float: left;
    margin-bottom: 20px;
    font-weight: bold;
}
.dBox1 em:hover{
    border:1px solid #f44158;
}
.dBox1 em.active1,.dBox em.open{
    border:1px solid #f44158;
    background:url(/content/images/selected.png) no-repeat right top #fff;
    background-size: 33px 33px;
}
.dBox2 em{
    display:inline-block;
    width: 120px;
    height: 52px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    font-style:normal;
    margin-right:6px;
    font-size:14px;
    cursor:pointer;
    color:#1d2b3c;
}
.dBox2 em:hover{
    border:1px solid #f44158;
}
.dBox2 em.active2,.dBox em.open{
    border:1px solid #f44158;
    background:url(/content/images/selected.png) no-repeat right top #fff;
    background-size: 26px 26px;
}
/*.dBox .inp_dmoney{ background:#fff; padding:0 5px; font-size:14px;height:36px; display:inline-block; border:1px solid #d4d4d4; font-style:normal; line-height:36px\9; width:80px;}*/
.grossPrice{
    font-size:14px;
    font-weight: 500;
    color:#f44158;
}
.grossPrice em{
    font-size:24px;
    color:#ff6600;
    font-weight:bold;
    font-style:normal;
    vertical-align:-3px;
    margin-right:5px;
}
.eIdStyle{
    width:75px;
    height:25px;
    background:rgba(150,150,150,1);
    border-radius: 100px;
    text-align: center;
    float: left;
    margin-left: 30px;
}
.eId{
    font-size:16px;
    font-family:PingFangSC-Semibold;
    color:rgba(255,255,255,1);
    line-height:25px;
    text-align: center;
}
.eName{
    /*width:95px;*/
    height:33px;
    font-size:24px;
    font-family:PingFangSC-Semibold;
    color:rgba(29,43,60,1);
    line-height:25px;
    float: left;
    text-indent: 5px;
}
.e_account_a{
    color: #FF224F;
    font-weight: bold;
    font-size: 13px;
    text-decoration: none;
}
.e_account_a:hover{
    color: #FF224F;
    font-weight: bold;
    font-size: 13px;
    text-decoration: none;
}
/*设置bootstrap switch样式*/
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary{
    color:#fff;
    background: linear-gradient(133.5deg,rgba(250,118,145,1),rgba(255,34,79,1));
    box-shadow: 0px 2px 4px 0px rgba(247,93,118,0.49);
    border-radius: 4px ;
}
.coverage_area{
    width: 100%;
    height:300px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    border-radius: 4px;
    border: 2px solid #C8C8C8;
}
.coverage_area table{
    width:102%;
    height:300px;
    color: rgba(29,43,60,1);
    font-weight: bold;
}
.coverage_area tr{
    height: 30px;
}
.coverage_area tr:nth-child(odd){
    background-color: #f4f4f5;
}
.coverage_area tr:nth-child(even){
    background-color: #fff;
}
.coverage_area tr td{
    height: 30px;
}
/*.coverage_area tr td input{*/
/*width: 20px;*/
/*height: 20px;*/
/*background-color: red;*/
/*}*/

/********************* modify by simmor **************************/
/*
    e-account / agent
*/
.active_agent {
    color: #f44158!important;
    border-bottom: solid 4px #f44158;
    text-decoration: none!important;
    padding-bottom: 5px;
}
.un_active {
    color: #1b2b3c!important;
    text-decoration:none!important;
    margin-left: 50px;
    padding-bottom: 5px;
}
.cropArea {
    background: #E4E4E4;
    overflow: hidden;
    width:500px;
    height:350px;
}
.myRibbon{
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 30%;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    /*width: 15em;*/
    z-index: 1.1;
    pointer-events: none;
    /*position: relative;*/
    width: 700px;
    height: auto;
    opacity: 0.5;
}
.myRibbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 42px 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

.popovers-rabbit {
    z-index: 999;
    position: absolute;
    right: 17%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    text-align: center;
    line-height: 20px;
    float: right;
    position: absolute;
    margin: 7px -5px 0 5px;
}

.show-error{
    position: absolute;
    right: 15%;
}

.qrNormal,.qrTest,.qrWeb{
    /* width: 380px; */
    /*height: 250px;*/
    background-color: white;
    margin: 0 auto;
    text-align: center;display: flex; flex-direction: column;
}
.qrcode-test-right,
.qrcode-test-left{
    position: absolute;
    font-weight: bold;
    font-size:16px;
    color: red;
    top: 50%;
    transform: translate(0 , -50%);
}
.qrcode-test-left{
    left: 5px;
}
.qrcode-test-right{
    right: 5px;
}

.qrBaseTask1,
.qrBaseTask2,
.qrBaseTask3,
.qrBaseTask4{
    width: 160px;
    background-color: white;
    margin: 0 auto;
}

.addDesign{
    width: 92px;
    height: 42px;
    border-radius: 3px;
    background: -webkit-linear-gradient(left,#35e8c0, #0c9fcb);
    box-shadow: 0 4px 8px 0 rgba(80, 197, 212, 0.26);
    color: #ffffff;
}

.trip-dropdown {
    position: relative;
    display: inline-block;
    width: 60px;
}
.trip-dropdown-content {
    display: none;
    flex-direction: column;
    position: absolute;
    left:50%;
    transform: translate(-50%,0);
    background-color: #f9f9f9;
    width: 90px;
    z-index: 999;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 4px;
}
.trip-dropdown:hover .trip-dropdown-content {
    /*display: block;*/
    display: flex;
}
.trip-dropdown-content button:first-child
{
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    border-top:0;
}
.trip-dropdown-content button:last-child
{
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
}

.trip-dropdown-content-div{
    border-top:1px solid rgba(200,200,200,1);
    width: 90px;
    height:40px;
    font-size:12px;
    font-family:SourceHanSansCN-Medium;
    font-weight:500;
    color:rgba(70,70,70,1);
    line-height:18px;
}
.new-btn-black,
.new-btn-white,
.new-btn-rad,
.new-btn{
    width: 80px;
    height: 40px;
    border-radius:4px
}
.new-btn-rad{
    background:linear-gradient(133deg,rgba(250,118,145,1) 0%,rgba(244,65,88,1) 100%);
    box-shadow:0px 4px 8px 0px rgba(244,65,88,0.3);
}
.new-btn-white{
    width: 60px;
    background:white;color:rgba(120,120,120,1);
    border:1px solid rgba(200,200,200,1);
}
.new-btn-black{
    width: 60px;
    background:rgba(29,43,60,1);
}

.pcLogin-content {
    display: none;
    position: absolute;
    left:50%;
    transform: translate(-50%,0);
    background-color: #f9f9f9;
    width: 90px;
    z-index: 999;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    /*padding: 12px 16px;*/
}

.pcLogin{
    position: relative;
}
.pcLoginImg{
    display: none;
    position: absolute;
    top: 35px;
    width:430px;
    height: 149px;
    left: -275px;
}

.top-btn-right{
    padding: 15px 5px;
}

.trip-btn-cancle{
    width:120px;
    height:40px;
    background:rgba(255,255,255,1);
    border-radius:5px;
    border:1px solid rgba(200,200,200,1);
}
.trip-btn{
    width:120px;
    height:40px;
    background:linear-gradient(133deg,rgba(250,118,145,1) 0%,rgba(244,65,88,1) 100%);
    border-radius:5px;
    color:rgba(255,255,255,1);
}
.trip-text{
    /*width:163px;*/
    height:25px;
    font-size:18px;
    font-family:PingFangSC-Medium;
    font-weight:500;
    color:rgba(29,43,60,1);
    line-height:25px;
    margin: auto;
    text-align: center
}
.trip-close{
    float: right;
    width: 38px;
    height: 38px;
}
.trip-warn-logo{
    margin: 0 99px 15px 99px;
    height: 130px;
    width: 114px;

}

.login-btn-red{
    width: 100%;
    margin: 25px 0 0 0;
    border-radius:20px;
    font-size: 20px;
    height: 40px;
}

.login-btn-red:hover{
    opacity: 0.95;
}

.login-btn-white{
    width: 100%;
    height:40px;
    border-radius:20px;
    margin-top: 20px;
    background-color:rgba(0,0,0,0);
    color: white;
    font-size: 14px;
    border:2px;
    border:1px solid #ccc;
}
.login-btn-white:hover{
    background-color:rgba(255,255,255,0.05);
}

.trip-start-type{
    display: inline;
    outline: none;
    font-size:12px;
    font-weight:400;
    color:rgba(150,150,150,1);
    line-height:17px;
}


.trip-dialog-tooltip{
    background-color: transparent !important;
    color:rgba(90,90,90,1);
    box-shadow:none;
    opacity: 1 !important;
}
.trip-dialog-tooltip>.tooltip-arrow{
    border-bottom-color:rgba(255,248,186,1) !important;
}
.trip-dialog-tooltip>.tooltip-inner{
    background-color:rgba(255,248,186,1);
}
.trip-dialog-tooltip>.tooltip-inner>p{
    font-size:14px;
    font-family:PingFangSC-Medium;
    font-weight:500;
    color:rgba(90,90,90,1);
    line-height:20px;
}
.trip-dialog-tooltip-img{
    width: 16px;
    height: 16px;
    background-image: url("../images/hoverFYI.png");
    background-repeat:no-repeat;
    background-size: 16px 16px;
    /*display: inline-block;*/
    /*margin-bottom: -3px;*/
}
.trip-dialog-tooltip-img:hover{
    background-image: url("../images/hover.png");
}

.trip-publish-tooltip{
    width: 400px;
    background-color:rgba(90,90,90,0) !important;
    color:rgba(90,90,90,1);
}
.trip-publish-tooltip>.tooltip-arrow{
    border-bottom-color:rgba(255,248,186,1) !important;
}
.trip-publish-tooltip>.tooltip-inner{
    max-width:400px!important;
    background-color:rgba(255,248,186,1);
}
.trip-publish-tooltip>.tooltip-inner>p{
    font-size:14px;
    font-family:PingFangSC-Medium;
    font-weight:500;
    color:rgba(90,90,90,1);
    line-height:20px;
}
.new-trip-model-content{
/*     overflow-x: auto; */
    width: 786px;
    height: 499px;
    background:rgba(245,245,245,1);
    border-radius:20px;
}
.new-trip-model-body-div{
    width:268px;
    height:186px;
    background:rgba(255,255,255,1);
    border-radius:20px;
    display: flex;
    float: left;
    justify-content: center;
    flex-direction: column;
    border:2px solid rgba(36,137,255,0);
    outline:none;

}
.game-list{
    background-repeat:no-repeat;
    background-size: 100% 100% !important;
    background-size: cover;
    width:24rem;
    margin: 0 1.5rem 1.5rem 0;
    border-radius:20px;
    display: flex;
    float: left;
    justify-content: center;
    flex-direction: column;
    border:2px solid rgba(36,137,255,0);
    outline:none;
    z-index: 0;
    position: relative;
}
.hover-btn-bg{
    text-align:center;
    width:23.7rem;
    height:23.7rem;
    margin: 0 1.5rem 1.5rem 0;
    border-radius:20px;
    float: left;
    border:2px solid rgba(36,137,255,0);
    color: #fff;
    background: rgba(0,0,0,0.9);
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    /*display: none;*/
}
.game-list:hover .hover-btn-bg {
    /*display: block;*/
    opacity: 0.9;
}

.hover-btn {
    position: static;
    background: none;
    width:120px;
    height:40px;
    border-radius:4px;
    color: rgba(210,205,200,1);
    border:1px solid rgba(210,205,200,1);
}
.hover-btn:hover {
    border:1px solid rgba(255,255,255,1);
    color: rgba(255,255,255,1);
}
.game-list-2{
    background-repeat:no-repeat;
    background-size: 100% 100% !important;
    background-size: cover;
    width:24rem;
    margin: 0 1.5rem 1.5rem 0;
    border-radius:20px;
    display: flex;
    float: left;
    justify-content: center;
    flex-direction: column;
    border:2px solid rgba(36,137,255,0);
    outline:none;
}
.game-list-title{
    /*position: absolute;*/
    color:#FFFFFF;
    font-size:24px;
    text-align: center;
}

.game-list-div{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    bottom: 0;
    left: 0;
    z-index: 5
}
.game-list-foot{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 17px 14px 17px;
}
.game-list-description{
    /*position: absolute;*/
    /*margin-top:3rem;*/
    color:#FFFFFF;
    /*width: 200px;*/
    font-size:14px;
    line-height:24px;
    text-align: center;
}

.fazx-top-img1,
.fazx-top-img{
    width: 100%;
    /*height: 150px;*/
}
.fazx-top-img1{
    display: none;
}
.fazx-serch{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
.fazx-serch-input{
    width: 265px;
    height: 40px;
    background: #E6E6E6;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    text-align: center;
}
.fazx-serch-img{
    position: absolute;
    transform: translate(0,-50%);
    left: 40px;
    top: 50%;
    width: 12px;
    height: 12px;
    /* background: #C8C8C8; */
}
.fazx-center{
    margin: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
}
.fazx-center-div{
    width: 21%;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #E6E6E6;
    margin: 10px 2%;
    position: relative;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.fazx-center-div-top-tag{
    position: absolute;
    width: 40px;
    height: 23px;
    background: #F44158;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    right: 0;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.fazx-center-div-top-img{
    width: 100%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.fazx-center-img-div{
    padding-bottom:50%;
    width: 100%;
}
.fazx-center-div-low{
    width: 90%;
    margin: 14px 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.fazx-center-div-low-tag{
    width: 40px;
    height: 20px;
    background: #F44158;
    border-radius: 4px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 18px;
    text-align: center;
}

.fazx-center-div-low-div1{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #313131;
    line-height: 25px;
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}
.fazx-center-div-low-div2{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8891AB;
    line-height: 17px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0;
}
.fazx-center-div-low-labeldiv{
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #8891AB;
    line-height: 18px;
    margin-top: 8px;
}

.fazx-center-div-low-label{
    background: #EAECF3;
    float: left;
    margin: 0px 10px 4px 0px;
    text-align: center;
    border-radius: 3px;
    padding: 5px;
    line-height: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fazx-page{
    position: relative;
    text-align: center;
}


.fazx-detail{
    margin: -19px;
}
.fazx-detail-left{
    flex: 1;
    border: 1px solid #E6E6E6;
}
.fazx-detail-detail img{
    max-width: 100%;
    margin:0 !important;
}

/* .fazx-detail-detail p{
   line-height: 2px;
} */

/*.fazx-detail-detail video{*/
    /*width: 677px;*/
    /*height: 380px;*/
/*}*/
/*@media (max-width: 1200px){*/
    /*.fazx-detail-detail video{*/
        /*width: 477px;*/
        /*height: 268px;*/
    /*}*/
/*}*/

/*@media (max-width: 992px){*/
    /*.fazx-detail-detail video{*/
        /*width: 257px;*/
        /*height: 144px;*/
    /*}*/
/*}*/

.fazx-detail-video-view{
    width: 100%;
    padding-bottom: 50%;
    position: relative;
}
.fazx-detail-video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    background-color: #0A0A0A;
}

.fazx-detail-detail p{
    margin: 20px 0px !important;
    line-height: 1.8;
    font-size: 16px;
    letter-spacing: 2px;
}
.fazx-detail-left-top{
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    flex-direction: row;
}
.fazx-detail-left-top-sc,
.fazx-detail-left-top-js{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 100%;
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #1D2B3C;
    line-height: 24px;
    outline: none;
    position: relative;
    margin: 0 20px;
}
.fazx-detail-left-top-sc{
    font-weight: 400;
    color: #787878;
}
.fazx-detail-left-top-line{
    width: 65px;
    height: 4px;
    background: #EAECF3;
    position: absolute;
    left: 0;
    bottom: 0;
}
.fazx-detail-hr{
    margin-top: 50px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
}
.fazx-detail-left-name{
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #8891AB;
    line-height: 22px;
    flex: 1;
}
.fazx-detail-left-download{
    float: left;
    width: 80px;
    height: 40px;
    background: linear-gradient(134deg, #FA7691 0%, #F44158 100%);
    box-shadow: 0px 4px 8px 0px rgba(244, 65, 88, 0.3);
    border-radius: 4px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.fazx-detail-detail{
    padding: 20px 80px;
}

.fazx-detail-right{
    width: 300px;
    border: 1px solid #E6E6E6;
    padding: 31px 31px 31px 31px;
}
.fazx-detail-right-top{
    width: 100%;
    position: relative;
    padding-bottom: 50%;
}
.fazx-detail-right-center-div{
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*flex-direction: column;*/
}
.fazx-detail-right-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.fazx-detail-right-title{
    margin-top: 5px;
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #262626;
    line-height: 36px;
    flex: 1;
    white-space:normal;
    word-break:break-all;
    word-wrap:break-word;
}
.fazx-detail-right-tag{
    width: 40px;
    height: 20px;
    background: #F44158;
    border-radius: 4px;
    text-align: center;
    color: #FFFFFF;
    margin: 10px;
}
.fazx-detail-right-desc{
    width: 214px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8891AB;
    line-height: 17px;
    margin-top: 10px;
    white-space:normal;
    word-break:break-all;
    word-wrap:break-word;
}
.fazx-detail-right-label{
    width: 58px;
    height: 22px;
    background: #EAECF3;
    margin: 10px 10px 0px 0px;
    float: left;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #8891AB;
    line-height: 20px;
    text-align: center;
}
.fazx-detail-push,
.fazx-detail-lxwm,
.fazx-detail-right-hqsfxl{
    width: 100%;
    height: 44px;
    background: linear-gradient(134deg, #FA7691 0%, #F44158 100%);
    box-shadow: 0px 4px 8px 0px rgba(244, 65, 88, 0.3);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 20px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    outline: none;
}
.fazx-detail-lxwm {
    background: #8891AB;
    box-shadow: none;
}
.fazx-detail-push{
    background: linear-gradient(134deg, #FA7691 0%, #F44158 100%);
}
.fazx-detail-right-hqsfxl1{
    width: 232px;
    height: 44px;
    position: relative;
    top: 50px;
}


.fazx-detail-right-line{
    position: absolute;
    transform: translate(0 , -50%);
    top: 50%;
    left: 20px;
    width: 16px;
    height: 16px;
}

.fazx-detail-tip{
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 254px;
    height: 160px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
}
.fazx-detail-tip-text{
    margin: 35px 0 10px 30px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1D2B3C;
    line-height: 25px;
    text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

}

.fazx-detail-lx3,
.fazx-detail-lx2,
.fazx-detail-lx1{
    height: 44px;
    border-radius: 4px 0px 0px 0px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.fazx-detail-lx2{
    border-radius: 0px 0px 0px 0px;
}
.fazx-detail-lx3{
    border-radius: 0px 4px 0px 0px;
}
.fazx-detail-lx-detail{
    width: 100%;
    background: #FFFFFF;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #E6E6E6;
    padding: 10px 10px 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.fazx-detail-lx-detail-span4,
.fazx-detail-lx-detail-span3,
.fazx-detail-lx-detail-span2,
.fazx-detail-lx-detail-span1{
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1D2B3C;
    line-height: 20px;
}

.fazx-detail-lx-detail-span2{
    color: #8891AB;
}


.fazx-detail-lx-detail-span3{
    margin: 0 auto;
}

.fazx-detail-lx-detail-span4{
    color: #8891AB;
    margin: 0 auto;
}

.zj-ajd-tip{
    width: 445px;
    height: 50px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #F44158;
    line-height: 25px;
    position: relative;
    left: 80px;
}
.zj-ajd-img{
    position: relative;
    left: 240px
}


.jxxb-new-trip-model-body-image{
    width: 80px;
    height: 80px;
    margin: 0 auto;
}
.jxxb-new-trip-model-body-div{
    width:300px;
    height:300px;
    background:rgba(255,255,255,1);
    border-radius:20px;
    display: flex;
    float: left;
    justify-content: center;
    flex-direction: column;
    /*border:2px solid rgba(36,137,255,0);*/
    outline:none;
    margin: 0 21px;
    position: relative;
}
.jxxb-new-trip-model-warning{
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(242,241,241,0.3);
}
.jxxb-model-title{
    font-size:18px;
    color:rgba(29,43,60,1);
    text-align: center;
    font-weight:500;
    font-family:PingFangSC-Medium;
}

.jxxb-model-phone{
    display: none;
}

.jxxb-model-body{
    margin: 12px 52px 10px 52px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.jxxb-model-foot{
    text-align: center;
    border:0;
}

.jxxb-model-flex{
    width: auto;
    height: auto
}


.new-trip-model-body-div:hover{
    border:2px solid rgba(36,137,255,1);
}

.new-trip-model-body-image{
    width: 30px;
    height: 30px;
    position: relative;
    top: -5px;
}

.new-trip-model-body-div1{
    margin: 20px auto;
    color:rgba(70,70,70,1);
    font-size:22px;
    text-align: center;
}

.new-trip-model-body-div-top{
	margin: 20px 0 5px 40px;
}

.new-trip-model-body-image-newd{
	width: 60px;
    height: 30px;
    position: relative;
    top: -13px;
    left: 205px;
}

.new-trip-model-body-image-newd1{
	width: 60px;
    height: 30px;
    position: relative;
    top: 12px;
    left: 205px;
}

.new-trip-model-body-div2-link{
	width:56px;
	height:20px;
	font-size:14px;
	font-family:PingFangSC-Medium,PingFang SC;
	font-weight:500;
	color:rgba(247,181,0,1);
	line-height:20px;
}


.trip-dialog-ac-span{
    width: 80px;
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6B6E71;
    line-height: 35px;
    margin-right: 3px;
}


.trip-dialog-ibean-div{
    font-size: 14px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: rgba(244,65,88,1);
    line-height: 24px;
    width: 200px;
}


.trip-dialog-ibean-div-show{
    width: 320px;
    height: 463px;
    background: rgba(255,255,255,1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
    border-radius: 4px;
    border: 1px solid rgba(230,230,230,1);
    position: absolute;
    top: 245px;
    z-index: 100;
}





.modal-top-left-span1{
	width:101px;
	height:25px;
	font-size:18px;
	font-family:PingFangSC-Medium,PingFang SC;
	font-weight:500;
	color:rgba(120,120,120,1);
	line-height:25px;
}

.modal-top-left-span2{
    position: relative;
    left:390px;
    width:48px;
	height:17px;
	font-size:12px;
	font-family:PingFangSC-Medium,PingFang SC;
	font-weight:500;
	color:rgba(150,150,150,1);
	line-height:17px;
}

.modal-top-left-span2-a{
	font-size:12px;
	font-family:PingFangSC-Medium,PingFang SC;
	font-weight:500;
	color:rgba(150,150,150,1);
	line-height:17px;
}


.new-trip-model-body-div2{
    width: 200px;
    font-size:14px;
    font-weight:400;
    color:rgba(129,139,151,1);
    line-height:24px;
    margin: 0 auto;
}

.new-trip-model-body-div3{
    font-size:14px;
    font-weight:400;
    line-height:24px;
    margin: 0 auto;
    display: none;
    color: rgb(215, 41, 18);
}

.modal-top-left{
	position: relative;
	left:135px;
}

.modal-middle{
	position: relative;
	left:135px;
}


.activity-div1{
	width:192px;
	height:24px;
	font-size:14px;
	font-family:PingFangSC-Regular,PingFang SC;
	font-weight:400;
	color:rgba(244,65,88,1);
	line-height:24px;
	position: relative;
	left:391px;
	top:-60px;
}
.activity-div2{
	position: relative;
	left:428px;
	top:-60px;
}

.push-activity-div1{
	height: 24px;
    font-size: 14px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: rgba(244,65,88,1);
    line-height: 24px;
    text-align: center;
}

.push-showBuy{
	width: 320px;
    height: 463px;
    background: rgba(255,255,255,1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
    border-radius: 4px;
    border: 1px solid rgba(230,230,230,1);
    /*padding: 26px 26px 26px 26px;*/
    position: absolute;
    left: 160px;
    top: -80px;
    z-index: 100;

}
.push-showBuyimg{
	position: relative;
    left: 285px;
    top: -453px;
    width: 40px;
    height: 40px;
    outline: none;
}
.push-showBuyimg1{
	margin: 26px;
    width: 280px;
	height: 395px;
}

.push-showBuyfont{
	width: 130px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 500;
    color: rgba(120,120,120,1);
    line-height: 17px;
    position: relative;
    left: 89px;
    top: -57px;
}





.showBuy{
    width: 320px;
    height: 463px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 4px 0px rgba(0,0,0,0.2);
    border-radius:4px;
    border:1px solid rgba(230,230,230,1);
    /*padding:26px 26px 26px 26px;*/
    position: relative;
    left: 330px;
    top: -520px;
    z-index: 100;
}
.showBuyimg{
	position: relative;
    left: 285px;
    top: -453px;
    width: 40px;
    height: 40px;
    outline: none;
}
.showBuyimg1{
	margin: 26px;
	width: 280px;
	height: 395px;
}

.showBuyfont{
	width: 130px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 500;
    color: rgba(120,120,120,1);
    line-height: 17px;
    position: relative;
    left: 100px;
    top: -57px;
}




.e-account-init-back{
    width:90px;
    height:40px;
    background:linear-gradient(135deg,rgba(250,118,145,1) 0%,rgba(244,65,88,1) 100%);
    border-radius:20px;
}
.e-account-init-back-p{
    width:64px;
    height:22px;
    font-size:16px;
    font-family:PingFangSC;
    font-weight:600;
    color:rgba(255,255,255,1);
    line-height:22px;
}
.e-account-init-code{
    width:107px;
    height:35px;
    background:linear-gradient(133deg,rgba(250,118,145,1) 0%,rgba(244,65,88,1) 100%);
    box-shadow:0px 2px 4px 0px rgba(255,34,79,0.2);
    border-radius:20px;
}
.e-account-init-code-p{
    width:95px;
    height:22px;
    font-size:14px;
    font-family:PingFangSC;
    font-weight:500;
    color:rgba(255,255,255,1);
    line-height:22px;
}
.e-account-init-button{
    width:477px;
    height:45px;
    color:white;
    background:linear-gradient(133deg,rgba(250,118,145,1) 0%,rgba(244,65,88,1) 100%);
    box-shadow:0px 2px 4px 0px rgba(255,34,79,0.2);
    border-radius:23px;
}
.e-account-init-code-tip{
    width:120px;
    height:40px;
    border-radius:5px;
    border:1px solid rgba(200,200,200,1);
}
.eaccount-detail-record{
    width:1288px;
    height:57px;
    background:rgba(250,250,251,1);
    border:1px solid rgba(230,230,230,1);
}
.rabbit-leader-info-grey{
    height:50px;
    padding-left: 20px;
    background:rgba(244,244,245,1);
    border:1px solid rgba(230,230,230,1);
}
.rabbit-leader-info-white{
    height:50px;
    padding-left: 20px;
    background:rgba(255,255,255,1);
    border:1px solid rgba(235,235,235,1);
}
.rabbit-leader-service {
    padding:20px 20px 20px 30px;
    margin:10px 10px 10px 10px;
    height:161px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 6px 0px rgba(223,231,231,0.6);
    border-radius:2px;
}
.rabbit-leader-service-l-data {
    padding-top:20px;
    font-size:48px;
    font-weight:600;
    color:#1D2B3C;
    line-height:67px;
}
.rabbit-leader-service-s-data {
    padding-top:20px;
    font-size:30px;
    font-weight:600;
    color:#1D2B3C;
    line-height:67px;
}
.rabbit-leader-service-xs-data {
    padding-top:5px;
    font-size:12px;
    font-weight:600;
    color:#C8C8C8;
    line-height:20px;
}
.rabbit-leader-detail-service {
    padding:20px 20px 10px 30px;
    margin:5px 10px 5px 10px;
    height:115px;
    background:#FAFAFAFF;
}
.rabbit-title{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.rabbit-text{
    font-size:14px;
    font-family:PingFangSC;
    font-weight:400;
    color:rgba(70,70,70,1);
    line-height:20px;
}

.to-chect-select {
    width: 119px;
    height: 40px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 1px solid rgba(200, 200, 200, 1);
}

.modal-dialog .form-control#field_description {
    display: table-row;
    min-height: 240px;
}
.edui-default .edui-editor-iframeholder {
    min-height: 240px;
}

.edui-editor-imagescale{
    display:none !important;
}
#edui105_body{
    display:none !important;
}
#edui4_content{
    height: auto !important;
}

.base-task-ban-ar{
    position: relative;
    overflow: visible;
}
.base-task-ban-ar:hover .base-task-ban-ar-prompt{
    display: block;
}
.base-task-ban-ar-prompt{
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: #4a4444;
    border-radius: 6px;
    padding: 4px;
}

.program-add-label{
    margin: 24px 0 0 10px;
    width: 70px;
    height: 40px;
    background: linear-gradient(135deg, #3E566F 0%, #1D2B3C 100%);
    box-shadow: 0px 4px 8px 0px rgba(31, 46, 64, 0.24), 0px 2px 2px 0px rgba(35, 50, 69, 0.35);
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}
.program-delete-label{
    width: 21px;
    height: 21px;
    margin-left: 50px;
}
.program-label{
    height: 40px;
}
.program-zip-name{
    width: 140px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #787878;
    margin-left: 11px;
}
.program-zip-img{
    width: 14px;
    height: 15px;
    margin-right: 6px;
}

.gropram-flex-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.modal-dialog .form-control#field_programText {
    display: table-row;
    min-height: 240px;
}

.modal-dialog .form-control#field_manualText {
    display: table-row;
    min-height: 240px;
}

.program-checkbox{
    margin: -3px 5px 0 0 !important;
    width: 18px;
    height: 18px;
}

.program-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}
.program-red-btn{
    width: 60px;
    height: 40px;
    background: linear-gradient(133deg, #FA7691 0%, #F44158 100%);
    box-shadow: 0px 4px 8px 0px rgba(244, 65, 88, 0.3);
    border-radius: 4px;
}
.program-white-btn{
    width: 60px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    color: #787878;
}
.program-blue-btn{
    width: 60px;
    height: 40px;
    background: linear-gradient(135deg, #35E8C0 0%, #0D9FCB 100%);
    box-shadow: 0px 4px 8px 0px rgba(80, 197, 212, 0.3);
    border-radius: 4px;
}
.program-black-btn{
    width: 80px;
    height: 40px;
    background: linear-gradient(135deg, #3E566F 0%, #1D2B3C 100%);
    box-shadow: 0px 4px 8px 0px rgba(31, 46, 64, 0.24), 0px 2px 2px 0px rgba(35, 50, 69, 0.35);
    border-radius: 4px;
}
.program-gray-btn{
    width: 80px;
    height: 40px;
    background: #C8C8C8;
    border-radius: 4px;
}
.program-notdelete-btn,
.program-delete-btn{
    width: 90px;
    height: 39px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #464646;
}

.program-notdelete-btn{
    color: #C8C8C8;
}

.program-model-div{
    width: 354px;
    height: 175px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 0;
}
.program-close{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
}
.program-model-text{
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1D2B3C;
    line-height: 25px;
    text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0 10px;
}

.program-center-detail-iwe-icon,
.program-center-detail-iwe{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.program-center-detail-iwe-icon{
    flex-direction: row;
    width: 100%;
}

.fazx-detail-left-name-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0 20px 0 30px;
    height: 60px;
    border-bottom: 1px solid #eeeeee;
}
.fazx-detail-left-name-icon{
    width: 25px;
    height: 25px;
    margin-right: 12px;
}
.fazx-detail-left-download-icon{
    width: 15px;
    height: 15px;
    margin-right: 6px;
}
.fazx-detail-flex-row-start{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}
.fazx-center-flex-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.navbar-team-game{
    position: relative;
}
.navbar-team-game-img{
    position: absolute;
    transform: translate(0 ,-50%);
    top: 50%;
    left: 10px;
    z-index: 0;
}
.navbar-tg-gb{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 114px;
    height: 44px;
    background: linear-gradient(315deg, #00C4FF 0%, #44D7B6 100%);
    border-radius: 22px 3px;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 53%;
    z-index: -1;
}

.fazx-detail-div{
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
}

.navbar-tg-gb-img{
    width: 24px;
    height: 24px;
}
.field_programText img{
    max-width: 100%;
    margin: 5px 0 !important;
}

.program-center-modal{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.program-center-modal-div1,
.program-center-modal-div2,
.program-center-modal-div3{
    width: 232px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #E6E6E6;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.program-center-modal-div1{
    flex-direction: row;
    padding: 11px 10px;
}
.program-center-modal-div2{
    padding: 20px 0 12px 0;
}
.program-center-modal-div3{
    align-items: flex-start;
    padding: 11px 10px;
}

.program-center-modal-close{
    width: 40px;
    height: 40px;
    margin-top: 20px;
}

.program-center-modal-btn{
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    width: 60px;
    height: 40px;
    background: linear-gradient(133deg, #FA7691 0%, #F44158 100%);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 4px 8px 0px rgba(244, 65, 88, 0.3);
    border-radius: 4px;
}

.program-center-modal-qr{
    width: 150px;
    height: 150px;
    background: #D8D8D8;
    border: 1px solid #979797;
    margin-bottom: 10px;
}

.program-logo-div-fa{
    height: 0;
    width: 100%;
    position: relative;
}
.program-logo-div{
    width: 100vw;
    height: 100%;
    background: #1D2B3C;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(-50%,-50%);
    top: -25px;
    left: 50%;
}
.program-logo{
    width: 79px;
    height: 30px;
}

@media (max-width: 767px){
    .navbar-tg-gb{
        transform: translate(0,-50%);
        top: 50%;
        left: 20px;
    }
    #designer-menu12{
        height: 56px;
    }

    .fazx-detail-div{
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
    }
    .fazx-detail-right,
    .fazx-detail-left{
        width: 100%;
        order:2;
        border: 0;
        padding: 0;
    }
    .fazx-detail-right{
        order:1;
    }
    .fazx-detail{
        margin: 0;
    }
    .fazx-detail-detail{
        padding: 20px;
        margin: 0px 0px 40px 0px;
    }

    .fazx-detail-right-center-div{
        padding: 15px 20px;
    }

    .program-center-detail-iwe{
        flex-direction: row;
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
    }

    .fazx-detail-push,
    .fazx-detail-lxwm,
    .fazx-detail-right-hqsfxl{
        order:2;
        margin-bottom: 0;
        flex: 1;
        border-radius: 0;
        box-shadow:none;
    }

    .program-center-detail-iwe-icon{
        order:1;
        flex: 1;
    }

    .fazx-center-div{
        width: 44%;
        margin: 10px 3%;
    }

    .fazx-center-flex-row{
        align-items: flex-start;
        flex-direction: column;
    }

    .fazx-center-div-low-div1{
        width: 100%;
    }
    .fazx-center-div-low-div2{
        width: 100%;
        /*display: -webkit-box;*/
        /*-webkit-line-clamp:2;*/
        /*white-space:pre-wrap;*/
        /*-webkit-box-orient: vertical;*/
    }

    .fazx-center-div-low-labeldiv{
        margin-top: 0;
    }
    .jxxb-model-title{
        display: none;
    }
    .jxxb-model-flex{
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    .jxxb-model-body{
        flex-direction: column;
        margin: 0;
        flex: 1;
        align-items: center;
    }
    .jxxb-new-trip-model-body-div{
        width: 80%;
        height: 45%;
    }

    .jxxb-model-foot{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .jxxb-model-foot-btn{
        margin: 20px 0 30px 0;
        order:-1;
    }

    .program-logo-div-fa{
        height: 50px;
    }
    .program-logo-div{
        top:50%;
    }

    .new-trip-model-body-div3,
    .jxxb-new-trip-model-warning{
        display: block;
    }

    .jxxb-model-phone{
        display: flex;
    }

    .jxxb-model-comp{
        display: none;
    }

}
.showPDF-div{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.showPDF-btn-warning{
    width: 100%;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
}
.showPDF-img-div{
    flex: 1;
    overflow-y: scroll;
    width: 100%;
}
.showPDF-img{
    width: 100%;
}
.showPDF-btn{
    width: 100%;
    height: 60px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: fixed;
    left: 0;
    bottom: 0;
}
.showPDF-btn-img{
    width: 19px;
    height: 23px;
    margin-right: 11px;
}
.showPDF-btn-text{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}

.ad-input{
    margin: -3px 2px 0 0 !important;
}

.ad-upload-btn{
    width: 120px;
    height: 40px;
    background: linear-gradient(135deg, #3E566F 0%, #1D2B3C 100%);
    box-shadow: 0px 4px 8px 0px rgba(31, 46, 64, 0.24), 0px 2px 2px 0px rgba(35, 50, 69, 0.35);
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-model-view{
    width: 900px;
    height: 450px;
    position: absolute;
    top: 50%;
    left:50%;
    margin: auto;
}

.ad-model-view-img{
    display: block;
    width: 900px;
    height: 450px;
}
.ad-model-view-phoneImg{
    width: 300px;
    height: 400px;
    display: none;
}

.ad-model-view-close{
    position: absolute;
    right: 0;
    top: 0;
    transform:translate(50%,-50%);
    width: 39px;
    height: 39px;
    border-radius: 40px;
    z-index: 1000;
    border: 2px solid #F8F4F4;
}

@media (max-width: 767px){
    .ad-model-view{
        width: 300px;
        height: 400px;
    }
    .ad-model-view-img{
        display: none;
    }
    .ad-model-view-phoneImg{
        display: block;
    }

    .ad-model-view-close{
        left: 50%;
        bottom: -24px;
        top: auto;
        transform: translate(-50%,100%);
    }
}
.program-model-image-div{
    position: absolute;
    top: 50%;
    left:50%;
    transform:translate(-50%,-50%);
}
.program-model-image-div-p{
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
}
.program-model-image-div-image{
    height: 136px;
    width: 354px;
}
.program-center-modal2{
    width: 354px;
    height: 218px;
    background: #FFFFFF;
    border-radius: 5px;
}
.program-center-modal2-btn{
    width: 120px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #C8C8C8;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #464646;
}
.trip-model-search-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 40px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    border: 1px solid #C8C8C8;
}
.trip-model-select{
    height: 100%;
    border-right: 1px solid #C8C8C8;
    background: #F4F4F4;
}
.trip-model-select-option{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #7A7979;
    line-height: 22px;
    text-align: center;
}
.trip-model-search-input{
    flex: 1;
    height: 100%;
    padding: 0 10px;
}
.trip-model-search-btn{
    flex: 1;
    width: 16px;
    height: 16px;
    z-index:9;
    margin: 0 15px;
}
.flex-column-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.flex-row-center{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.navbar-approve{
    width:55px;
    height:24px;
    border-radius:13px;
    margin-top: 6px;
    margin-bottom: 6px;
    background:linear-gradient(133deg,rgba(250,118,145,1) 0%,
    rgba(244,65,88,1) 100%);
}

h2.captain-title {
    float: left;
}
@media (min-width: 768px) and (max-width: 991px) {
    table .hidden-sm {
        display: block !important;
    }
}
@media (max-width: 767px) {
    .trip-model-search-div {
        width: 310px;
    }
    .pull-right {
        margin-bottom: 10px;
    }
    .trip .pull-right, .trip-model .pull-right {
        left: 20px;
    }
    h2.captain-title {
        float: none;
    }
    .checkBtn.new-btn {
        margin-bottom: 10px;
    }
}

/*navbar样式--start--*/
#container .nav{
    /* height: 50px;
    background-color: transparent; */
    margin: 0;
    max-width: 100%;
    vertical-align: middle;
    color: #ffffff;
}
#container .nav .nav_left{
    float: left;
    position: absolute;
    top:0;
}
/*小屏幕尺寸图片位置*/
@media (max-width: 730px) {
    #container .nav .nav_left img{
        max-width: 200px;
        position: relative;
        z-index: 10;
        top: 0;
        margin-top: 3px;
        left: 2%;

    }

}
/*大屏幕尺寸图片位置*/
@media (min-width: 730px) {
    #container .nav .nav_left img{
        max-width: 200px;
        position: relative;
        z-index: 10;
        top: 5px;
        margin-top: 15px;
        margin-left: 30%;
    }
}

@media (min-width: 768px) {
    .login-box {
        float: right;
        padding: 10px 35px 35px 35px;
        /*height: 366px;*/
        width:  401px;
        border: 1px solid rgba(255, 255, 255, .8) ;
        background: rgba(255, 255, 255, .2);
        border-radius: 5px;
    }
    a:hover, a:focus {
        /*color: #fff;*/
        text-decoration: none;
    }
    .nav_left {
        left: 2%;
    }
    #container .nav .n_right{
        width: 100%;
        float: right;
        background: transparent;
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        z-index: 999;
    }
    #container .nav .n_right ul{
        margin-top: 14px;
        width: 720px;
        /*background: #d0e9c6;*/
        position: absolute;
        right: 2%;
        top:36%;
        float: right;
        /*padding-left: 35%;*/
    }
    #container .nav .n_right ul li{
        /*width:15%;*/
        /* line-height: 135px; */
        float: left;
        list-style: none;
        margin-left: 10%;
        /*background-color: #f5e79e;*/
    }
    @media (max-width: 1081px) {
        #container .nav .n_right ul{
            width: 480px;
            /*background: #d0e9c6;*/
            position: absolute;
            right: 5%;
            top:32%;
            float: right;
            /*padding-left: 35%;*/
        }
        #container .nav .n_right ul li{
            /*width:15%;*/
            /* line-height: 135px; */
            float: left;
            list-style: none;
            margin-left: 8%;
            /*background-color: #f5e79e;*/
        }
    }
    #container .nav .n_right ul li a{
        /*width: 100%;*/
        color: #ffffff;
        opacity: 0.6;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 1.5px;
        text-decoration: none;
        padding: 0;
        /*background-color: #f5e79e;*/
    }

    #container .nav .n_right ul li a:hover{
        color: #ffffff;
        opacity: 1;
        font-size: 18px;
        font-weight: 600;
        /*letter-spacing: -0.2px;*/
        padding-bottom: 18px;
        /*border-bottom: solid #ffffff 4px;*/
    }
    #container .nav .n_right ul li:nth-child(4) a {
        color: #ffffff;
        opacity: 1;
        font-size: 18px;
        font-weight: 600;
        padding: 0 0 24px 0;
        border-bottom: solid #ffffff 4px;
    }
}



/*小屏幕尺寸图片位置*/
@media (max-width: 767px) {
    #rabbit-info {
        display: none;
    }
    .login-box {
        width: 100%;
        border: 1px solid rgba(255, 255, 255, .8) ;
        background: rgba(255, 255, 255, .2);
        border-radius: 5px;
        padding: 10px;
    }

    /* 覆盖bootspt css*/
    .navbar-inverse .navbar-nav > li > a {
        color: #fff;
    }
    .nav > li {
        /* opacity: 0.2; */
        border-top: solid 1px rgba(2,7,13,.2);
    }
    a:hover, a:focus {
        /*color: #fff;*/
        text-decoration: none;
    }
    .navbar-inverse .navbar-toggle {
        border: none!important;
    }
    .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
        background-color: #1d2b3c;
    }
    /* 覆盖bootspt css*/

    #container .nav .n_header{
        transition: all .3s;
        height: 60px;
        padding-top: 5px;
    }
    #container .nav .n_right{
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        background: #1d2b3c;
        border: none;
    }
    #container .nav .n_right ul{
        /* margin-top: 2%; */
        background-color: #1d2b3c;
    }
    .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
        padding: 0;
    }
    #container .nav .nav_left img {
        max-width: 130px;
        max-height: 52px;
        margin: 0;
        position: relative;
        z-index: 10;
        margin-top: 10px;
    }

    /**
     * 切换pc端和molie logo
     *
     */
    #container .nav .nav_left .pc_logo{
        display: none!important;
    }
    #container .nav .nav_left .molibe_logo {
        max-width: 95px;
        display: block!important;
    }
    /*navbar样式--end--*/
}

.ellipsis_two_line {
    /* 显示1行省略。。。*/
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.ellipsis_five_line {
    /* 显示1行省略。。。*/
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}


.btn-more {
    float: right;
    height: 42px;
    width: 200px;
    /*max-width: 528px;*/
    text-align: center;
    line-height: 42px;
    color: #fff;
    font-size: 18px;
    margin-top: 40px;
    outline: none;
    /*padding: 20px 10px 10px 10px;*/
    /*background: -webkit-linear-gradient(left, #FA7691 , #F44158); !* Safari 5.1 - 6.0 *!*/
    /*background: -o-linear-gradient(right, #FA7691, #F44158); !* Opera 11.1 - 12.0 *!*/
    /*background: -moz-linear-gradient(right, #FA7691, #F44158); !* Firefox 3.6 - 15 *!*/
    /*background: linear-gradient(to right, #FA7691 , #F44158); !* 标准的语法 *!*/
    background: -webkit-linear-gradient(left, #35E8C0 , #0D9FCB); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #35E8C0, #0D9FCB); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #35E8C0, #0D9FCB); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #35E8C0 , #0D9FCB); /* 标准的语法 */
    border-radius: 60px;
    cursor: pointer;
}

/*.login-box {*/
    /*float: right;*/
    /*padding: 10px 35px;*/
    /*height: 350px;*/
    /*width:  401px;*/
    /*border: 1px solid rgba(255, 255, 255, .8) ;*/
    /*background: rgba(255, 255, 255, .2);*/
    /*border-radius: 5px;*/
/*}*/

.content-rabbit {
    position: absolute;
    top: 124px;
    left: 0;
    right: 0;
    padding: 0 4%;
}


.apply-btn {
    background: -webkit-linear-gradient(left, #35E8C0 , #0D9FCB); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #35E8C0, #0D9FCB); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #35E8C0, #0D9FCB); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #35E8C0 , #0D9FCB); /* 标准的语法 */
    height: 42px;
    text-align: center;
    line-height: 42px;
    color: #fff;
    font-size: 18px;
    border-radius: 60px;
    margin-top: 15px;
    cursor: pointer;
}

.lg-input {
    width: 100%;
    height: 42px;
    line-height: 42px;
    border-radius: 60px;
    padding-left: 50px;
}

.lg-img-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 20px;
    height: 20px;
}

.btn-login {
    background: -webkit-linear-gradient(left, #FA7691 , #F44158); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #FA7691, #F44158); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #FA7691, #F44158); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #FA7691 , #F44158); /* 标准的语法 */
    height: 42px;
    text-align: center;
    line-height: 42px;
    font-size: 18px;
    color: #fff;
    border-radius: 60px;
}

.active-title {
    color: #50E3C2!important;
    border-bottom: 1px solid #50E3C2!important;
}

.down {
    margin-top: 50px;
    overflow: hidden;
}
.down h1 {
    margin: 0;
    height: 150px;
    line-height: 150px;
    text-align: center;
    color: #000;
    font-size: 24px;
    font-weight: bolder;
    background: url("../images/red-line-decoration.png") no-repeat center;
}

.down .pc_div .cover_img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.down .pc_div .p2 {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.mouseover_QRimg {
    width: 100px;
    height: 100px;
    z-index: 999;
}

.more-finding {
    background: -webkit-linear-gradient(left, #FA7691 , #F44158); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #FA7691, #F44158); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #FA7691, #F44158); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #FA7691 , #F44158); /* 标准的语法 */
    height: 42px;
    text-align: center;
    line-height: 42px;
    font-size: 18px;
    color: #fff;
    border-radius: 60px;
    width: 250px;
    margin: 30px auto;

}

.pc_div dl {
    /*box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0);*/
    transition: all 0.5s;
}

.pc_div dl:hover {
    transform: scale(1.01);
    box-shadow:0px 6px 16px 0px rgba(0,0,0,0.27);
}

/**
  ***********************************footer    start **************************************************
 */

/*@media screen and (-webkit-min-device-pixel-ratio:0) {*/

#container-footer {
    margin: 0 -4.3%;
}
#container-footer .foot-www {
    width: 100%;
    height: 100%;
}

#container-footer .foot-www .partner {
    width: 100%;
    margin: 0;
}

#container-footer .foot-www .download {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    /* text-align: center; */
    /*height: 800px;*/
    background: url("../images/app-bg@2x.jpg") no-repeat;
    background-size: 100% 100%;
}

#container-footer .foot-www .download .whole {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    display: flex;
    padding: 60px 0px 30px 0px;
}

#container-footer .foot-www .download .whole img {
    width: 60%;
}

#container-footer .foot-www .download .whole span {
    width: 50%;
    /* display: table-cell; */
}

#container-footer .foot-www .download .whole .download_left {
    /* padding-top: inherit; */
}

#container-footer .foot-www .download .whole .download_left div {
    margin-top: 40px;
}

#container-footer .foot-www .download .android, #container-footer .foot-www .download .ios {
    width: 170px;
    height: 50px;
    border-radius: 4px;
    border: solid 2px #ffffff;
    float: left;
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    margin-left: 3%;
}

#container-footer .foot-www .download .down {
    width: 300px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: solid 2px #ffffff;
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    position: relative;
    /* margin: -13% 0 10% 18%; */
}

#container-footer .foot-www .company {
    width: 100%;
    height: 100px;
    background-color: #13202f;
    color: #ffffff;
    position: relative;
    margin-top: -3%;
}

#container-footer .foot-www .company ul {
    width: 520px;
    height: 50px;
    margin: 0 auto;
    line-height: 50px;
}

#container-footer .foot-www .company ul li {
    width: 130px;
    height: 27px;
    line-height: 27px;
    font-size: 12px;
    /*letter-spacing: -0.3px;*/
    text-align: left;
    float: left;
    list-style: none;
    /*background-color: #e7e7e7;*/
}

#container-footer .foot-www .company ul li a {
    /*text-decoration: none;*/
    height: 17px;
    line-height: 6.5;
    color: #50e3c2;
    position: relative;
}

#container-footer .foot-www .company ul li a:hover {
    border-bottom: 1px;
    color: #50e3c2;
}

#container-footer .foot-www .bot {
    position: relative;
    bottom: 0;
    height: 106px;
    width: 100%;
    background-color: transparent;
}

#container-footer .foot-www .foot_left {
    width: 40%;
    height: 106px;
    float: left;
    /*background-color: #d0e9c6;*/
    background-color: #1d2b3c;
}

#container-footer .foot-www .foot_left img {
    max-width: 228px;
    max-height: 52px;
    margin: 4% 0 0 15%;
}

#container-footer .foot-www .foot_right {
    width: 60%;
    height: 106px;
    float: left;
    overflow: hidden;
    /*background-color: #f5e79e;*/
    background-color: #1d2b3c;
}

#container-footer .foot-www .foot_right ul {
    width: 100%;
    height: 106px;
    line-height: 106px;
    /*line-height: 65px;*/
    /*margin: 0 0 0 40%;*/
    /*background-color: #eea236;*/
}

#container-footer .foot-www .foot_right ul li {
    width: 88%;
    list-style: none;
    /*background-color: #d0e9c6;*/
    position: relative;
}

#container-footer .foot-www .foot_right .use_license {
    /*width: 520px;*/
    height: 25%;
    text-align: right;
    /*line-height: 85px;*/
    /*background-color: #eea236;*/
}

#container-footer .foot-www .foot_right .use_license a {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    color: #ffffff;
    text-decoration: none;
}

#container-footer .foot-www .foot_right .use_license .version {
    font-size: 14px;
    color: #e7e7e7;
    opacity: 0.5;
    border: solid 1px #e7e7e7;
    margin-right: 1%;
    padding: 0 0.5% 0 1%;
}

#container-footer .foot-www .foot_right .Copyright-new p {
    width: 100%;
    height: 17px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.1px;
    text-align: right;
    color: #f3f3f3;
    margin-left: -0.1%;
    /*margin-top: 1.5%;*/
}

#container-footer .content .con_bottom .con_word .terms h2 {
    text-align: left;
    /*padding-left: 10%;*/
    font-size: 32px;
}

.terms .padd_left {
    padding: 0 10% 0 10%;
    line-height: 2.4;
    font-size: 18px;
}

/*
  å¼¹å‡ºè’™ç‰ˆæµ®å±‚
   */

.black_overlay {
    display: none;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 1001;
    -moz-opacity: 0.7;
    opacity: .70;
    filter: alpha(opacity=70);
}

.topFade {
    display: none;
}
.white_content {
    display: none;
    position: fixed;
    left: 36%;
    bottom: 21%;
    /*width: 483px;*/
    /*height: 482px;*/
    background-color: white;
    z-index: 1002;
    overflow: hidden;
    border-radius: 5px;
}

/**
    å›žåˆ°é¡¶éƒ¨æŒ‰é’®æ ·å¼
  **/

.return {
    max-width: 61px;
    max-height: 61px;
    /*float: right;*/
    /*margin:-6% 3.6% 0 0;*/
    position: fixed;
    right: 15px;
    bottom: 15px;
    display: none;
    cursor: pointer;
    bottom: 50px;
}


#qrCode qrcode canvas {
    background: rgba(255,255,255,1);
    padding: 10px;
}

@media (min-width: 768px) {
    .trip-list-mobile {
        display: none;
    }
    #nav-findings {
        height: 80px;
        background-color: #1d2b3c;
    }
    .foot-www .partner h1 {
        margin: 0;
        height: 150px;
        line-height: 150px;
        text-align: center;
        color: #000;
        font-size: 24px;
        font-weight: bolder;
        background: url("../images/red-line-decoration.png") no-repeat center;
    }
}

/**
    手机屏幕
  **/

@media (max-width: 767px) {
    .trip-list {
        display: none!important;
    }
    .trip-list-mobile {
        display: block;
    }
    .down {
        padding: 10px!important;
    }
    .personal-info {
        width: 100%!important;
        margin-bottom: 30px;
        text-align: center;
    }
    #container-footer {
        /* padding-bottom: 15px; */
    }
    .foot-www .partner {
        display: none !important;
    }
    .footer_moblie {
        display: block !important;
        background-color: #fff;
    }
    .bot_molibe {
        display: block !important;
    }
    .footer_moblie .partner {
        /* margin-bottom: 10px; */
    }
    .footer_moblie .partner h1 {
        margin: 0;
        height: 80px;
        line-height: 80px;
        text-align: center;
        color: #1a2b3c;
        font-size: 16px;
        font-weight: 700;
        background: url("../images/red-line-decoration.png") no-repeat center;
        background-size: 246px 9px;
    }
    .footer_moblie .partner img {
        width: 100%;
        margin: 0 auto;
        padding: 0 10px;
        margin-bottom: 15px;
        /* margin: 0 10px; */
    }
    #container-footer .foot-www .download .down {
        display: none;
        width: 90px;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        border: solid 1px #ffffff;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        position: relative;
        margin: 0;
    }
    #container-footer .foot-www .download .moblie_down {
        display: block !important;
        width: 90px;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        border: solid 1px #ffffff;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        position: relative;
        margin: 0;
    }
    #container-footer .foot-www .download .whole .download_left {
        text-align: center;
    }
    #container-footer .foot-www .download .whole .download_left div {
        margin: 20px auto 0px auto;
    }
    #container-footer .foot-www .download .whole {
        width: 100%;
        display: flex;
        padding: 0 24px 20px 30px;
    }
    #container-footer .foot-www .company {
        height: auto;
    }
    .company ul {
        display: none;
    }
    .company_moblie {
        text-align: center;
        display: flex !important;
        height: 100px;
        padding-top: 15px;
        font-size: 14px;
    }
    .company_moblie a {
        color: #50e3c2 !important;
    }
    .company_moblie_left {
        width: 50%;
    }
    .company_moblie_right {
        width: 50%;
        height: 65px;
        border-left: 1px solid rgba(255, 255, 255, .2);
    }
    .bot {
        display: none;
    }
    .bot_molibe {
        background-color: #1d2b3c;
        color: #fff;
        padding: 20px;
        display: block !important;
    }
    .bot_molibe .use_license {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .bot_molibe .copyright {
        color: #f3f3f3;
        opacity: 0.8;
        font-size: 12px;
        margin-bottom: 5px;
    }
    .bot_molibe .logo img {
        width: 130px;
        height: 30px;
    }
    .white_content {
        display: none;
        position: fixed;
        left: 0;
        bottom: 21%;
        /* width: 483px; */
        /* height: 482px; */
        /* background-color: white; */
        z-index: 1002;
        overflow: hidden;
        border-radius: 5px;
        text-align: center;
    }
    .white_content img {
        width: 100%;
    }
}
/**
  ***********************************footer    end **************************************************
 */
/**
 ***********************************rabbit-findings page    start **************************************************
 */

.content-rabbit-findings {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;

}
.search-area .type-ul li {
    cursor: pointer;
    float: left;
    width: 33%;
    outline: none;

}
.search-area .type-ul .type-ul-li:hover {
    color: #FF1B4A;
    border-bottom: 4px solid #FF1B4A;
    padding-bottom: 10px;
}
.type-ul-li-focus {
    color: #FF1B4A;
    border-bottom: 4px solid #FF1B4A;
    padding-bottom: 10px;
}
.content-rabbit-designer .search-area .type-ul .type-ul-li-de:hover {
    color: #FF1B4A;
    border-bottom: 4px solid #FF1B4A;
    padding-bottom: 0px;
}
.type-ul-li-de-focus {
    color: #FF1B4A;
    border-bottom: 4px solid #FF1B4A;
    padding-bottom: 0px;
}


.search-area .type-ul .location-ul {
    position: absolute;
    color: #1d2b3c;
    padding: 5px 0px;
    font-size: 16px;
    top: 40px;
    left: 20px;
    width: 100px;
    border-radius: 5px;
    background-color: #ffffff;
    /*display: none;*/
    z-index: 2;
    box-shadow: 0 5px 25px #C8C8C8;
}
.search-area .type-ul .location-ul li {
    width: 100%;
    outline: none;
}
.search-area .type-ul .location-ul li:hover {
    color: #FF1B4A;
}

.triangle {
    position: absolute;
    top: 10px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #505050;
}

.more-loading {
    cursor: pointer;
    background: -webkit-linear-gradient(left, #FA7691 , #F44158); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #FA7691, #F44158); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #FA7691, #F44158); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #FA7691 , #F44158); /* 标准的语法 */
    height: 42px;
    text-align: center;
    line-height: 42px;
    font-size: 18px;
    color: #fff;
    border-radius: 60px;
    width: 250px;
    margin: 30px auto;
    outline: none;

}
.desig-author a {
    color: #98A1A5;
}
.desig-author a:hover {
    color: #337ab7;
    text-decoration: underline;

}

/**
 ***********************************rabbit-findings page    end **************************************************
 */


/**
************************************** 公开报名   start ****************************************
 */

.edit-openreginer {
    background: -webkit-linear-gradient(left, #35E8C0 , #0D9FCB); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #35E8C0, #0D9FCB); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #35E8C0, #0D9FCB); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #35E8C0 , #0D9FCB); /* 标准的语法 */
    color: #FFF;
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 5px;
    outline: none;
    margin-right: 20px;
}
/*#returnRecord thead tr th {*/
    /*border-top: 1px solid #dddddd;*/
/*}*/
/*#returnRecord thead tr th:first-child {*/
    /*border-left: 1px solid #ddd;*/
/*}*/
/*#returnRecord thead tr th:last-child {*/
    /*border-right: 1px solid #ddd;*/
/*}*/
#returnRecord {
    border: 1px solid #ddd;
    margin: 0;
}

.team-introduce >div {
    padding: 0px 0px 10px 10px;
}

.team-info div span img {
    width: 15px;
    margin: -1px;
}
.team-info >div {
    padding-bottom: 10px;
}

.save-btn-QR {
    text-align: center;
    background: -webkit-linear-gradient(left, #FA7691 , #F44158); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #FA7691, #F44158); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #FA7691, #F44158); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #FA7691 , #F44158); /* 标准的语法 */
    height: 40px;
    width: 140px;
    line-height: 40px;
    color: #fff;
    border-radius: 4px;
    outline: none;
    box-shadow:0px 6px 12px 0px rgba(247,93,118,0.49);
}

.QR-link{
    width:100%;
    height:140px;
    box-shadow:0px 1px 3px 0px rgba(0,0,0,0.2);
    border-radius:4px;
    border:1px solid rgba(200,200,200,1);
    word-break:break-all;/*英文url换行*/
}


.a-mask {
    position: absolute;
    top: 0;
    left:0;
    height: 100%;
    width:  100%;
    background-color: rgba(0,0,0,0.3)
}
/*#returnRecord tbody tr {*/
    /*border-left: 1px solid #ddd;*/
/*}*/
